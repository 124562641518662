import axios from 'axios';
import store from '../redux/store';
import { clearAuth, refreshToken } from '../redux/authSlice';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        const tokenExpired = error.response.headers['x-token-expired'] === 'Token expired';
        if (tokenExpired) {
          const { user_id, access_token } = store.getState().auth.loginState;
          store.dispatch(refreshToken(user_id, access_token));
        } else {
          store.dispatch(clearAuth());
        }
      }
    }
    return Promise.reject(error);
  },
);
