import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAppContext } from '../../../../../context/context';
import { fetchTeamRoles } from '../../../../../redux/rolesSlice';

import { addNotification } from '../../../../../redux/tooltipSlice';

import PageTitle from '../../../../utilities/page-title/page-title';
import Button from '../../../../utilities/button/button';
import BackdropLoading from '../../../../utilities/backdrop-loading/backdrop-loading';
import Modal from '../../../../utilities/modal/modal';

import EmployeeForm from '../form/employee-form';

import { convertValueByType } from '../../../../utilities/utils';

import styles from '../employees.module.scss';

const EmployeeFormModal = ({
  employee,
  setEmployee,
  isLoading,
  isEditMode,
  isOpen,
  closeModal,
  saveChanges,
  errors,
}) => {
  const { token } = useAppContext();
  const dispatch = useDispatch();

  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const rolesOptionsStatus = useSelector((state) => state.roles.status);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rolesOptionsStatus === 'idle') {
          await dispatch(fetchTeamRoles(token)).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, rolesOptionsStatus]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setEmployee((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleRoleChange = (value) => {
    handleInputChange({ target: { name: 'team_role_id', value: value } }, 'number');
  };

  if (!employee) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <section className={styles.employee_edit_section}>
        <div className={styles.content}>
          <PageTitle name={isEditMode ? 'Edit Employee' : 'Add Employee'} />
          <div className={styles.form_container}>
            {isLoading && <BackdropLoading />}
            <EmployeeForm
              employee={employee}
              roles={rolesOptions}
              onInputChange={handleInputChange}
              onRoleChange={handleRoleChange}
              errors={errors}
            />
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                disabled={isLoading}
                title={isEditMode ? 'Save Changes' : 'Add employee'}
                onClick={saveChanges}
                style={{ margin: 0 }}
              />
              <Button
                className={styles.button}
                variant={'grey'}
                disabled={isLoading}
                title={'Cancel'}
                onClick={closeModal}
                style={{ margin: 0 }}
              />
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default EmployeeFormModal;
