import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { useAppContext } from '../../../../context/context';
import { fetchDeletedTransactionsReport } from '../../../../redux/reportsSetSlice';

import {
  HeaderReport,
  ReportFilterPanel,
  TotalRangeFilter,
  DateRangeFilter,
  DropdownTypes,
  PAGINATION,
} from '../components';
import DeletedTransactionsSection from './deleted-transactions-section';

import styles from './deleted-transactions.module.scss';

function DeletedTransactions() {
  const { token } = useAppContext();

  const dispatch = useDispatch();

  const paramsInit = {
    start_date: new Date(),
    end_date: new Date(),
    page: PAGINATION.page,
    per_page: PAGINATION.perPage,
  };

  const [filterParams, setFilterParams] = useState(paramsInit);

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  useEffect(() => {
    dispatch(
      fetchDeletedTransactionsReport({
        token,
        ...filterParams,
      }),
    );
  }, [dispatch, filterParams, token]);

  const handleSetFilterParams = (params) => {
    setFilterParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const handleClearFilters = () => {
    setFilterParams(paramsInit);
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Deleted Transactions'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx(styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel
          filterParams={filterParams}
          dropdowns={[
            DropdownTypes.employee,
            DropdownTypes.payment_type,
            DropdownTypes.captured_id,
            DropdownTypes.terminal_id,
            DropdownTypes.order_type_id,
          ]}
          onClearFilterParams={handleClearFilters}
          onUpdateParams={handleSetFilterParams}
        />
        <TotalRangeFilter rangeFrom={filterParams?.from} rangeTo={filterParams?.to} onUpdate={handleSetFilterParams} />
      </div>
      <div className={clsx(styles.boxBody, styles.borderBox)}>
        <DateRangeFilter
          showPreset={true}
          startDate={filterParams.start_date}
          endDate={filterParams.end_date}
          onUpdateParams={handleSetFilterParams}
        />
      </div>
      <DeletedTransactionsSection filterParams={filterParams} onUpdateParams={handleSetFilterParams} />
    </div>
  );
}

export default DeletedTransactions;
