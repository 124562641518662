import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLocations, updateOtherSettings } from '../../../../redux/locationSlice';
import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../utilities/page-title/page-title';
import Checkbox from '../../../utilities/checkbox/checkbox';
import Button from '../../../utilities/button/button';

import styles from './other-settings.module.scss';

function OtherSettings() {
  // Global State
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const locations = useSelector((state) => state.locations.locations);

  // Local State
  const [currentLocation, setCurrentLocation] = useState({});
  const [printerQueueEnabled, setPrinterQueueEnabled] = useState(false);
  const [orderNumberStartingFromOne, setOrderNumberStartingFromOne] = useState(false);
  const [onlyShowPriceGreaterThanZero, setOnlyShowPriceGreaterThanZero] = useState(false);
  const [flag, setFlag] = useState(false);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations) {
      const location = locations.find((location) => location.id === locationId);
      if (location) setCurrentLocation(location);
    }
  }, [locations, locationId]);

  useEffect(() => {
    if (currentLocation) {
      setPrinterQueueEnabled(currentLocation.is_printer_queue_enable || false);
      setOrderNumberStartingFromOne(currentLocation.order_number_starting_from_one || false);
      setOnlyShowPriceGreaterThanZero(currentLocation.only_show_price_greater_than_zero || false);
    }
  }, [currentLocation]);

  // Handlers
  const handleCheckboxChange = (setter) => {
    setter((prev) => !prev);
    setFlag(true);
  };

  const handleSave = () => {
    if (flag) {
      dispatch(
        updateOtherSettings({
          isPrinterQueueEnabled: printerQueueEnabled,
          isOrderNumberStartingFromOne: orderNumberStartingFromOne,
          isOnlyShowPriceGreaterThanZero: onlyShowPriceGreaterThanZero,
          token,
          locationId,
        }),
      );
      setFlag(false);
    }
  };

  return (
    <div className={styles.menu_settings}>
      <PageTitle name={'Other Settings'} />
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: 'Printer Queue Enable' }}
          handleOnChange={() => handleCheckboxChange(setPrinterQueueEnabled)}
          isChecked={printerQueueEnabled}
        />
        <span className={styles.menu_item_name} onClick={() => handleCheckboxChange(setPrinterQueueEnabled)}>
          Printer Queue Enable
        </span>
      </div>
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: 'Order number starting from 1 everyday' }}
          handleOnChange={() => handleCheckboxChange(setOrderNumberStartingFromOne)}
          isChecked={orderNumberStartingFromOne}
        />
        <span className={styles.menu_item_name} onClick={() => handleCheckboxChange(setOrderNumberStartingFromOne)}>
          Order number starting from 1 everyday
        </span>
      </div>
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: 'Only show price greater than zero?' }}
          handleOnChange={() => handleCheckboxChange(setOnlyShowPriceGreaterThanZero)}
          isChecked={onlyShowPriceGreaterThanZero}
        />
        <span className={styles.menu_item_name} onClick={() => handleCheckboxChange(setOnlyShowPriceGreaterThanZero)}>
          Only show price greater than zero?
        </span>
      </div>
      <Button className={styles.save_button} title={'Save'} onClick={handleSave} />
    </div>
  );
}

export default OtherSettings;
