import React, { useMemo } from 'react';

import Modal from '../../../../components/utilities/modal/modal';
import Selector from '../../../../components/utilities/selector/selector';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';

import { IoIosArrowDown } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';

import useFormValidation from '../../../utilities/useFormValidation';
import { registrationSchema } from './validation';

import styles from './sales-taxes-edit-modal.module.scss';

const SELECTOR_OPTIONS = [
  { value: 'Percentage', label: 'Percentage' },
  { value: 'Dollar', label: 'Dollar' },
];

function EditSalesTaxesModal({ isModalOpen, setIsModalOpen, editableData, setEditableData, onUpdate }) {
  const { errors, setErrors, validate } = useFormValidation(registrationSchema);

  const validatedData = useMemo(
    () => ({
      name: editableData?.editableName,
      rate: editableData?.editableRate,
      tax_type: editableData?.editableType,
    }),
    [editableData],
  );

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditableData({});
    setErrors({});
  };

  const handleSelectType = (selectedValue) => {
    setEditableData((prevState) => ({
      ...prevState,
      editableType: selectedValue,
    }));
  };

  const handleUpdateModalData = () => {
    if (validate(validatedData)) {
      onUpdate();
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} className={styles.edit_taxes_modal}>
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Edit {editableData.editableName}</h3>
          <div className={styles.cross_icon} onClick={handleCloseModal}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.edit_input_wrapper}>
            <Input
              label={'Tax Name'}
              value={editableData.editableName}
              name="editableName"
              onChange={(e) =>
                setEditableData((prevState) => ({
                  ...prevState,
                  editableName: e.target.value,
                }))
              }
              placeholder="New name"
              required={true}
              className={styles.tax_name_input}
              errors={errors.name?._errors}
            />
          </div>

          <div className={styles.edit_input_wrapper}>
            <Input
              label={'Tax Rate'}
              type="number"
              name="editableRate"
              value={editableData.editableRate}
              onChange={(e) =>
                setEditableData((prevState) => ({
                  ...prevState,
                  editableRate: Number(e.target.value),
                }))
              }
              placeholder="New rate"
              required={true}
              className={styles.tax_percent_input}
              errors={errors.rate?._errors}
            />
          </div>

          <div className={styles.edit_input_wrapper}>
            <Selector
              label="Type"
              icon={IoIosArrowDown}
              items={SELECTOR_OPTIONS}
              placeholder={editableData.editableType}
              onSelectItem={handleSelectType}
              selectedItem={editableData.editableType}
              required
              errors={errors.tax_type?._errors}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <Button title="Update" onClick={handleUpdateModalData} />
          <Button title="Cancel" onClick={handleCloseModal} />
        </div>
      </div>
    </Modal>
  );
}

export default EditSalesTaxesModal;
