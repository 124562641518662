import React, { useState, useEffect } from 'react';

import Modal from '../../../../components/utilities/modal/modal';
import Search from '../../../../components//utilities/search/search';
import Checkbox from '../../../../components/utilities/checkbox/checkbox';

import { RxCross2 } from 'react-icons/rx';
import styles from './sales-taxes-assign-modal.module.scss';

function AssignItemsSalesTaxesModal({
  isModalOpen,
  setIsModalOpen,
  onClose,
  onUpdate,
  menuItems,
  taxes,
  currentTaxId,
}) {
  // Local state
  const [searchTermAssignModal, setSearchTermAssignModal] = useState(''); // Search input
  const [selectAll, setSelectAll] = useState({
    id: 0,
    name: 'Select All',
    status: false,
  });
  const [goods, setGoods] = useState([]); // Menu items in correct format
  const [selectedModalItemsIds, setSelectedModalItemsIds] = useState([]); // Selected menu items ids or initial menu items ids from backend
  const [filteredAssignModalItems, setFilteredAssignModalItems] = useState(goods); // Menu items filtered by value in Search input
  const [currentTax, setCurrentTax] = useState({}); // Current tax object

  // Effects
  useEffect(() => {
    if (menuItems && menuItems.length > 0 && currentTaxId) {
      const transformedMenuItems = menuItems.map((item) => ({
        id: item.id,
        name: item.name,
        status: item.tax_ids.includes(currentTaxId),
      }));
      setGoods(transformedMenuItems);
    }
  }, [menuItems, currentTaxId]);

  useEffect(() => {
    setFilteredAssignModalItems(goods);
  }, [goods]);

  useEffect(() => {
    const currentTaxObject = taxes.filter((tax) => tax.id === currentTaxId);
    setCurrentTax(currentTaxObject);
  }, [taxes, currentTaxId]);

  useEffect(() => {
    if (currentTax && Array.isArray(currentTax.item_ids)) {
      setSelectedModalItemsIds(currentTax.item_ids);
    }
  }, [currentTax]);

  useEffect(() => {
    const filteredMenuItems = goods.filter((item) =>
      item.name.toLowerCase().includes(searchTermAssignModal.toLowerCase()),
    );
    setFilteredAssignModalItems(filteredMenuItems);
  }, [goods, searchTermAssignModal]);

  // Handlers
  const handleSetAssignModalResult = (term) => {
    setSearchTermAssignModal(term);
  };

  const handleSearchAssignModalClean = () => {
    setSearchTermAssignModal('');
  };

  const handleAssignModalAll = (id, isChecked) => {
    // do not delete "id" here, it is important
    setSelectAll((prev) => {
      return { ...prev, status: isChecked };
    });

    if (isChecked) {
      const allItemIds = filteredAssignModalItems.map((item) => item.id);
      setSelectedModalItemsIds(allItemIds);
    } else {
      setSelectedModalItemsIds([]);
    }
  };

  const handleAssignModalCheckboxChange = (id, isChecked) => {
    setSelectedModalItemsIds((prev) => {
      let updatedItems;

      if (isChecked) {
        updatedItems = [...prev, id];
      } else {
        updatedItems = prev.filter((item) => item !== id);
      }

      setSelectAll({
        ...selectAll,
        status: updatedItems.length === goods.length,
      });

      return updatedItems;
    });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => onClose()} className={styles.assign_items_modal}>
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Apply Items to Sales Tax</h3>
          <div className={styles.cross_icon} onClick={() => onClose()}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.search}>
            <Search
              placeholder="Search Items"
              onSearchResults={handleSetAssignModalResult}
              onClear={handleSearchAssignModalClean}
              autoFilter={false}
            />
          </div>
          <div className={styles.menu_items}>
            <div className={styles.menu_select_all}>
              <Checkbox item={selectAll} handleOnChange={handleAssignModalAll} isChecked={selectAll.status} />
              <span className={styles.menu_item_name}>Select All</span>
            </div>
            <div className={styles.menu_list_of_items}>
              {filteredAssignModalItems.map((item) => (
                <div key={item.id} className={styles.menu_item}>
                  <Checkbox
                    item={item}
                    handleOnChange={handleAssignModalCheckboxChange}
                    isChecked={selectedModalItemsIds.includes(item.id)}
                  />
                  <span className={styles.menu_item_name}>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.update_button}
            onClick={() => onUpdate(currentTaxId, selectedModalItemsIds)}
          >
            Apply to Tax
          </button>
          <button type="button" className={styles.cancel_button} onClick={() => setIsModalOpen(false)}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AssignItemsSalesTaxesModal;
