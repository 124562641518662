import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import { fetchEmployeesTimesheet } from '../../../../redux/timesheetSlice';

import { useAppContext } from '../../../../context/context';
import { transformToISOFormat } from '../../../utilities/utils';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Selector from '../../../../components//utilities/selector/selector';
import DatePicker from '../../../utilities/date-picker/date-picker';
import TimesheetList from './timesheet-list';

import { IoIosArrowDown } from 'react-icons/io';

import styles from './timesheet.module.scss';

const DATE_FORMAT = 'MM/dd/yyyy';

function Timesheet() {
  // Hooks
  const dispatch = useDispatch();
  const { token } = useAppContext();

  // Store
  const records = useSelector((state) => state.timesheet.timesheet_records);
  const status = useSelector((state) => state.timesheet.status);

  // Local state
  const [fullListOfRecords, setFullListOfRecords] = useState([]); // The full list of records

  const [rolesOption, setRolesOption] = useState([]); // The full list of possible roles
  const [selectedRole, setSelectedRole] = useState({}); // Selected role

  const [employeesOption, setEmployeesOption] = useState([]); // The full list of possible employees
  const [selectedEmployee, setSelectedEmployee] = useState({}); // Selected employee

  const [fromDate, setFromDate] = useState(transformToISOFormat(new Date(), true));
  const [toDate, setToDate] = useState(transformToISOFormat(new Date(), true));

  // Effect (refetching records)
  useEffect(() => {
    dispatch(
      fetchEmployeesTimesheet({
        token,
        start_date: fromDate,
        end_date: toDate,
        team_role_id: selectedRole.value,
        employee_id: selectedEmployee.value,
      }),
    );
  }, [dispatch, token, selectedRole.value, selectedEmployee.value, fromDate, toDate]);

  // Effect (setting a complete list of records)
  useEffect(() => {
    if (records?.length > 0) {
      setFullListOfRecords((prevList) => {
        const combinedRecords = [...prevList, ...records]; // Merge new records into the existing list while ensuring uniqueness

        return combinedRecords.filter(
          (item, index, self) => index === self.findIndex((r) => r.team_id === item.team_id),
        );
      });
    }
  }, [records]);

  // Effect (setting the list of roles)
  useEffect(() => {
    if (fullListOfRecords?.length > 0) {
      setRolesOption(
        fullListOfRecords
          .map((item) => ({
            label: item.team_role_name,
            value: item.team_role,
          }))
          .filter((role, index, self) => index === self.findIndex((r) => r.value === role.value)),
      );
    }
  }, [fullListOfRecords]);

  // Effect (setting the list of employees)
  useEffect(() => {
    const filteredEmployees = selectedRole.value
      ? fullListOfRecords.filter((item) => item.team_role === selectedRole.value)
      : fullListOfRecords;

    const uniqueEmployees = new Set();
    const employees = filteredEmployees
      .filter((item) => {
        if (uniqueEmployees.has(item.team_id)) return false;
        uniqueEmployees.add(item.team_id);
        return true;
      })
      .map((item) => ({
        label: item.team_name,
        value: item.team_id,
      }));

    setEmployeesOption(employees);
  }, [fullListOfRecords, selectedRole]);

  // Handlers
  const handleSelectRole = (value) => {
    if (value === 'All roles') {
      setSelectedRole({ label: 'All roles', value: null });
    } else {
      setSelectedRole({ label: value, value: +value });
    }
    setSelectedEmployee({ label: 'All employees', value: null });
  };

  const handleSelectEmployee = (value) => {
    if (value === 'All employees') {
      setSelectedEmployee({ label: 'All employees', value: null });
    } else {
      setSelectedEmployee({ label: value, value: +value });
    }
  };

  const handleSelectDate = (date, setter) => {
    const result = transformToISOFormat(date, true);
    setter(result);
  };

  return (
    <div className={styles.timesheet}>
      <PageTitle name={'Timesheet'} className={styles.title} />
      <div className={styles.filters}>
        <div className={styles.filter_wrapper}>
          <label>Role</label>
          <Selector
            className={styles.selector}
            icon={IoIosArrowDown}
            items={rolesOption}
            additionalItems={[{ label: 'All roles', value: null }]}
            selectedItem={selectedRole.label}
            onSelectItem={(newValue) => handleSelectRole(newValue)}
            initialPlaceholderVisibility={false}
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>Employee</label>
          <Selector
            className={styles.selector}
            icon={IoIosArrowDown}
            items={employeesOption}
            additionalItems={[{ label: 'All employees', value: null }]}
            selectedItem={selectedEmployee.label}
            onSelectItem={(newValue) => handleSelectEmployee(newValue)}
            initialPlaceholderVisibility={false}
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>From Date</label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => handleSelectDate(date, setFromDate)}
            dateFormat={DATE_FORMAT}
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>To Date</label>
          <DatePicker
            selected={toDate}
            onChange={(date) => handleSelectDate(date, setToDate)}
            dateFormat={DATE_FORMAT}
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.container} role="table" aria-label="Data list">
          <TimesheetList records={records} isLoading={status === 'loading'} fromDate={fromDate} toDate={toDate} />
        </div>
      </div>
    </div>
  );
}

export default Timesheet;
