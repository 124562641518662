import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../../../context/context';

import { addNewEmployee, updateExistingEmployee } from '../../../../../redux/employeesSlice';

import useFormValidation from '../../../../utilities/useFormValidation';
import { registrationSchema } from '../validation';

const getEmployee = (employees, id) => {
  return employees.find((item) => item.id === parseFloat(id));
};

const useEmployee = (employees) => {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const initialState = {
    isModalOpen: false,
    isEditMode: false,
    currentEmployeeId: null,
    employee: {},
  };

  const [state, setState] = useState(initialState);
  const { errors, validate, resetErrors } = useFormValidation(registrationSchema);

  useEffect(() => {
    if (state.currentEmployeeId) {
      const foundEmployee = getEmployee(employees, state.currentEmployeeId);
      setState((prev) => ({ ...prev, employee: foundEmployee }));
    }
  }, [employees, state.currentEmployeeId]);

  const handleAddNewEmployee = () => {
    setState({
      ...initialState,
      isModalOpen: true,
      employee: {},
    });
  };

  const handleEditEmployee = (id) => {
    setState({
      isModalOpen: true,
      isEditMode: true,
      currentEmployeeId: id,
      employee: getEmployee(employees, id),
    });
  };

  const handleSaveChanges = async () => {
    if (validate(state.employee)) {
      try {
        await dispatch(
          state.isEditMode
            ? updateExistingEmployee({ id: state.currentEmployeeId, updatedDetails: state.employee, token })
            : addNewEmployee({ newEmployeeDetails: state.employee, token }),
        ).unwrap();
        handleModalClose();
      } catch (error) {
        console.error('Failed to save employee:', error);
      }
    }
  };

  const handleModalClose = () => {
    setState(initialState);
    resetErrors();
  };

  return {
    ...state,
    errors,
    setEmployee: (employee) => setState((prev) => ({ ...prev, employee })),
    handleModalClose,
    handleAddNewEmployee,
    handleEditEmployee,
    handleSaveChanges,
  };
};

export default useEmployee;
