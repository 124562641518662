import React, { useEffect, useRef, useCallback } from 'react';

import styles from './modal.module.scss';
import clsx from 'clsx';

const Modal = ({ message, isOpen, onClose, className, style, children }) => {
  const modalRef = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen) return null;

  return (
    <div className={clsx(styles.modal, className)} style={style}>
      <div className={styles.modal_content} ref={modalRef}>
        {children ? (
          children
        ) : (
          <>
            <span className={styles.modal_message}>{message}</span>
            <button className={styles.modal_button} onClick={onClose}>
              OK
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
