import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './edit-timesheet-details.module.scss';

import { updateExistingTimesheetDetails } from '../../../../redux/timesheetSlice';
import { useAppContext } from '../../../../context/context';
import {
  transformToISOFormat,
  receiveTimeWithoutTimeZoneCorrection,
  transformToISOFormatWithoutTimeZoneCorrection,
} from '../../../utilities/utils';
import DatePicker from '../../../utilities/date-picker/date-picker';

function EditTimesheetDetails() {
  // Hooks
  const dispatch = useDispatch();
  const { token } = useAppContext();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Store
  const timesheet_details_records = useSelector((state) => state.timesheet.timesheet_details_records);

  // Local state
  const [employeeName, setEmployeeName] = useState(''); // Data from URL params
  const [recordId, setRecordId] = useState(''); // Data from URL params

  const [fromDate, setFromDate] = useState(new Date()); // Date and time selector
  const [toDate, setToDate] = useState(new Date()); // Date and time selector

  const [recordedId, setRecordedId] = useState(null); // Data from backend
  const [recordedStatus, setRecordedStatus] = useState(null); // Data from backend

  const [fromPeriod, setFromPeriod] = useState(null); // The date for returning to the previous view with the correct time
  const [toPeriod, setToPeriod] = useState(null); // The date for returning to the previous view with the correct time

  // Effect (Set data from URL params)
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmployeeName(params.get('employee_name'));
    setRecordId(Number(params.get('record_id')));
    setFromPeriod(params.get('from_date'));
    setToPeriod(params.get('to_date'));
  }, [location]);

  // Effect (Set data from the backend)
  useEffect(() => {
    if (!timesheet_details_records) return;

    const record = timesheet_details_records.find((record) => record.id === recordId);

    if (record) {
      if (record.id !== null) {
        setRecordedId(record.id);
      } else {
        setRecordedId(0);
      }

      if (record.status !== null) {
        setRecordedStatus(record.status);
      } else {
        setRecordedStatus('-');
      }

      if (record.clock_in !== null) {
        setFromDate(receiveTimeWithoutTimeZoneCorrection(record.clock_in));
      }

      if (record.clock_out !== null) {
        setToDate(receiveTimeWithoutTimeZoneCorrection(record.clock_out));
      }
    }
  }, [timesheet_details_records, recordId]);

  // Handlers
  const handleUpdateButtonClick = () => {
    const newDetails = {
      employee_id: Number(id),
      records: [
        {
          id: recordedId,
          clock_in: transformToISOFormatWithoutTimeZoneCorrection(fromDate),
          clock_out: transformToISOFormatWithoutTimeZoneCorrection(toDate),
        },
      ],
    };
    dispatch(
      updateExistingTimesheetDetails({
        updatedDetails: newDetails,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        handleBackButtonClick();
      })
      .catch((error) => {
        console.error('Failed to update timesheet:', error);
      });
  };

  const handleBackButtonClick = () => {
    const path = `/employee/${id}/timesheet_details?employee_name=${employeeName}&from_date=${fromPeriod}&to_date=${toPeriod}`;
    navigate(path);
  };

  const handleSelectDate = (date, setter) => {
    if (date instanceof Date) {
      setter(date, false);
    }
  };

  return (
    <div className={styles.edit_timesheet_details}>
      <div className={styles.back_button}>
        <button type="button" onClick={handleBackButtonClick}>
          Back to Timesheet
        </button>
      </div>
      <div className={styles.header}>
        <div className={styles.name}>{`Adjust Time: ${employeeName}`}</div>
        <div className={styles.date}>
          {`Date: ${transformToISOFormat(fromDate, true)} - ${transformToISOFormat(toDate, true)}`}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.table_header}>
          <div className={styles.table_number}>#</div>
          <div className={styles.table_clockin}>Clock In</div>
          <div className={styles.table_clockout}>Clock Out</div>
          <div className={styles.table_status}>Status</div>
        </div>
        <div className={styles.table_body}>
          <div className={styles.table_row}>
            <div className={styles.table_number_row}></div>
            <div className={styles.table_clockin_row}>
              <DatePicker
                selected={fromDate}
                onChange={(date) => handleSelectDate(date, setFromDate)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                className={styles.datepicker}
                placeholderText="Select a date and time"
              />
            </div>
            <div className={styles.table_clockout_row}>
              <DatePicker
                selected={toDate}
                onChange={(date) => handleSelectDate(date, setToDate)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                className={styles.datepicker}
                placeholderText="Select a date and time"
              />
            </div>
            <div className={styles.table_status_row}>{recordedStatus}</div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <button type="button" onClick={handleUpdateButtonClick}>
          Update
        </button>
        <button type="button" onClick={handleBackButtonClick}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default EditTimesheetDetails;
