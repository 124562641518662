import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { useAppContext } from '../../../../context/context';
import { fetchLocations, updateExistingBatchCloseTime, updateExistingTimeZone } from '../../../../redux/locationSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Selector from '../../../utilities/selector/selector';
import Label from '../../../utilities/label/label';
import Button from '../../../utilities/button/button';
import DatePicker from '../../../utilities/date-picker/date-picker';

import { parseTimeToISO, parseTimeToHours } from '../../../utilities/utils';

import { IoIosArrowDown } from 'react-icons/io';

import clsx from 'clsx';
import styles from './batch-close-time.module.scss';

const DATE_FORMAT = 'p';

const timezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).format('Z');
  return {
    label: `(GMT${offset}) ${timezone}`,
    value: `${timezone}`,
  };
});

function BatchCloseTime() {
  // Global State
  const locations = useSelector((state) => state.locations.locations);
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Local State
  const [selectedBatchCloseTime, setSelectedBatchCloseTime] = useState('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [isBatchCloseTimeWasChanged, setIsBatchCloseTimeWasChanged] = useState(false);
  const [isTimeZoneWasChanged, setIsTimeZoneWasChanged] = useState(false);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations.length > 0) {
      const { batch_close_time, time_zone } = locations.find((location) => location.id === locationId);
      setSelectedBatchCloseTime(batch_close_time);
      setSelectedTimeZone(time_zone);
    }
  }, [locations, locationId]);

  // Handlers
  const handleDateChange = (value) => {
    handleSelectReportTime(parseTimeToHours(value, DATE_FORMAT));
  };

  const handleSelectReportTime = (selectedValue) => {
    setSelectedBatchCloseTime(selectedValue);
    setIsBatchCloseTimeWasChanged(true);
  };

  const handleSelectTimeZone = (selectedValue) => {
    setSelectedTimeZone(selectedValue);
    setIsTimeZoneWasChanged(true);
  };

  const handleUpdateBatchCloseTime = () => {
    if (isBatchCloseTimeWasChanged) {
      dispatch(
        updateExistingBatchCloseTime({
          updatedTime: selectedBatchCloseTime,
          token,
          locationId,
        }),
      );
      setIsBatchCloseTimeWasChanged(false);
    }
  };

  const handleUpdateTimeZone = () => {
    if (isTimeZoneWasChanged) {
      dispatch(
        updateExistingTimeZone({
          updatedTimeZone: selectedTimeZone,
          token,
          locationId,
        }),
      );
      setIsTimeZoneWasChanged(false);
    }
  };

  const handleSave = async () => {
    handleUpdateBatchCloseTime();
    handleUpdateTimeZone();
  };

  return (
    <div className={styles.batch_close_time}>
      <PageTitle name={'Batch Close Reporting Time'} />
      <div className={styles.selector_wrapper}>
        <DatePicker
          selected={parseTimeToISO(selectedBatchCloseTime, DATE_FORMAT)}
          onChange={handleDateChange}
          label="Batch Close Time"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat={DATE_FORMAT}
          timeCaption="Time"
          wrapperClassName={styles.date_picker}
        />
      </div>
      <div className={styles.time_zone_wrapper}>
        <Label text="Time Zone" />
        <Selector
          icon={IoIosArrowDown}
          items={timezones}
          placeholder={selectedTimeZone}
          onSelectItem={handleSelectTimeZone}
          selectedItem={selectedTimeZone}
        />
      </div>
      <Button
        className={clsx(
          styles.save_button,
          isBatchCloseTimeWasChanged === false && isTimeZoneWasChanged === false ? styles.disabled : null,
        )}
        title={'Save'}
        onClick={handleSave}
      />
    </div>
  );
}

export default BatchCloseTime;
