import { IoTrash } from 'react-icons/io5';

import Selector from '../../../utilities/selector/selector';
import DatePicker from '../../../utilities/date-picker/date-picker';
import List from '../../../utilities/list/list';

import { parseTimeToISO, parseTimeToHours } from '../../../utilities/utils';
import { DATE_FORMAT, statusValues } from './constants';

import styles from './hours-list.module.scss';

const HoursList = ({ hours, onStatusChange, onOpeningChange, onClosingChange, onDelete, isLoading }) => {
  const renderStatusSelector = (item) => (
    <Selector
      items={statusValues}
      selectedItem={item.is_open}
      onSelectItem={(value) => onStatusChange(item.id, value, 'is_open')}
    />
  );
  const renderOpeningTime = (item) => (
    <DatePicker
      selected={parseTimeToISO(item.opening_time, DATE_FORMAT)}
      onChange={(value) => onOpeningChange(item.id, parseTimeToHours(value, DATE_FORMAT), 'opening_time')}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat={DATE_FORMAT}
      timeCaption="Time"
      wrapperClassName={styles.date_picker}
    />
  );

  const renderClosingTime = (item) => (
    <DatePicker
      selected={parseTimeToISO(item.closing_time, DATE_FORMAT)}
      onChange={(value) => onClosingChange(item.id, parseTimeToHours(value, DATE_FORMAT), 'closing_time')}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat={DATE_FORMAT}
      timeCaption="Time"
      wrapperClassName={styles.date_picker}
    />
  );

  const columnConfig = [
    {
      key: 'week_day',
      header: 'Day',
      cellClassName: styles.cell,
    },
    {
      key: 'is_open',
      header: 'Status',
      render: renderStatusSelector,
      className: styles.status_selector,
    },
    {
      key: 'opening_time',
      header: 'Opening Time',
      render: renderOpeningTime,
    },
    {
      key: 'closing_time',
      header: 'Closing Time',
      render: renderClosingTime,
    },
  ];

  const rowActions = [
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete hours', className: styles.row_actions_button },
  ];

  return <List data={hours} columnConfig={columnConfig} rowActions={rowActions} isLoading={isLoading} />;
};

export default HoursList;
