import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { decodeJwt } from 'jose';
import { logIn, logOut, refreshToken as refreshTokenApi } from '../api/auth';
import { addNotification } from './tooltipSlice';

const STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  EXPIRED_DATE: 'expired_date',
};

const storageUtils = {
  setStorageAuthData: (accessToken, refreshToken, refreshExpiredDate) => {
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(STORAGE_KEYS.EXPIRED_DATE, refreshExpiredDate);
  },

  clearStorageAuthData: () => {
    Object.values(STORAGE_KEYS).forEach((key) => localStorage.removeItem(key));
  },

  getStorageAuthData: () => ({
    accessToken: localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
    refreshToken: localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN),
    tokenExpiredDate: localStorage.getItem(STORAGE_KEYS.EXPIRED_DATE),
  }),

  isAuthenticated: () => !!localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
};

const initialState = {
  isAuthenticated: storageUtils.isAuthenticated(),
  loginState: {
    user_name: '',
    user_id: '',
    email: '',
    location_id: '',
    location_name: '',
    access_token: storageUtils.getStorageAuthData().accessToken || null,
    refresh_token: storageUtils.getStorageAuthData().refreshToken || null,
    tokenExpiredDate: storageUtils.getStorageAuthData().tokenExpiredDate || null,
  },
  status: 'idle',
  error: null,
};

export const loginUser = createAsyncThunk('auth/login', async ({ userEmail, password }) => {
  const response = await logIn(userEmail, password);
  return response.data;
});

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async ({ email, terminal_id, refresh_token }, { dispatch }) => {
    try {
      const response = await logOut(email, terminal_id, refresh_token);
      storageUtils.clearStorageAuthData();
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to Logout: ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const refreshToken = createAsyncThunk('auth/refresh', async ({ userId, refreshToken }, { dispatch }) => {
  try {
    const response = await refreshTokenApi(userId, refreshToken);
    return response.data;
  } catch (error) {
    dispatch(
      addNotification({
        message: `Failed to refresh token: ${error?.message}`,
        status: 'failed',
      }),
    );
    storageUtils.clearStorageAuthData();
    throw error;
  }
});

const updateStateWithToken = (state, accessToken, refreshToken, expiredDate) => {
  if (!accessToken) {
    storageUtils.clearStorageAuthData();
    return false;
  }

  const decodedToken = decodeJwt(accessToken);
  state.isAuthenticated = true;
  state.loginState = {
    ...state.loginState,
    user_name: decodedToken?.userName,
    user_id: decodedToken?.userId,
    email: decodedToken?.email,
    location_id: decodedToken?.locationId,
    location_name: decodedToken?.locationName,
    access_token: accessToken,
    refresh_token: refreshToken,
    tokenExpiredDate: expiredDate,
  };
  return true;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.isAuthenticated = false;
      state.loginState = initialState.loginState;
      storageUtils.clearStorageAuthData();
    },
    checkAuthState: (state) => {
      const { accessToken, refreshToken, tokenExpiredDate } = storageUtils.getStorageAuthData();
      if (accessToken) {
        updateStateWithToken(state, accessToken, refreshToken, tokenExpiredDate);
      } else {
        clearAuth(state);
      }
    },
    changeLocationName: (state, action) => {
      state.loginState.location_name = action.payload;
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      // Login
      .addCase(loginUser.pending, setLoading)
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        setSucceeded(state);
        const { access_token, refresh_token, refresh_token_expired_date_time } = payload;
        storageUtils.setStorageAuthData(access_token, refresh_token, refresh_token_expired_date_time);
        updateStateWithToken(state, access_token, refresh_token, refresh_token_expired_date_time);
      })
      .addCase(loginUser.rejected, setFailed)
      // Logout
      .addCase(logoutUser.pending, setLoading)
      .addCase(logoutUser.fulfilled, (state) => {
        setSucceeded(state);
        state.isAuthenticated = false;
        state.loginState = initialState.loginState;
      })
      .addCase(logoutUser.rejected, setFailed)
      // Refresh token
      .addCase(refreshToken.pending, setLoading)
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        setSucceeded(state);
        const { access_token, refresh_token, refresh_token_expired_date_time } = payload;
        storageUtils.setStorageAuthData(access_token, refresh_token, refresh_token_expired_date_time);
        updateStateWithToken(state, access_token, refresh_token, refresh_token_expired_date_time);
      })
      .addCase(refreshToken.rejected, setFailed);
  },
});

export const { clearAuth, checkAuthState, changeLocationName } = authSlice.actions;
export default authSlice.reducer;
