import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAppContext } from '../../../../context/context';

import {
  fetchWorkingHours,
  createWorkingHours,
  updateExistingWorkingHours,
  removeWorkingHours,
} from '../../../../redux/workingHoursSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import HoursList from './hours-list';
import AddNewHours from './modals/add-new-hours';

import Button from '../../../utilities/button/button';
import PageTitle from '../../../utilities/page-title/page-title';
import { isEqual } from '../../../utilities/utils';
import { isWrongRange, isTimeRangeOverlapping, convertStringToBoolean } from './utils';

import styles from './hours.module.scss';

function Hours() {
  const dispatch = useDispatch();

  const { token } = useAppContext();

  const workingHours = useSelector((state) => state.hours.working_hours);
  const initialWorkingHours = useSelector((state) => state.hours.initial_working_hours);
  const workingHoursStatus = useSelector((state) => state.hours.status);

  const [fields, setFields] = useState(workingHours || []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasUnsavedChanges = useMemo(() => isEqual(fields, initialWorkingHours), [fields, initialWorkingHours]);

  useEffect(() => {
    if (workingHoursStatus === 'idle') {
      dispatch(fetchWorkingHours(token));
    }
  }, [dispatch, workingHoursStatus, token]);

  useEffect(() => {
    setFields(workingHours);
  }, [workingHours]);

  useEffect(() => {}, []);

  const isHoursValidated = useCallback(
    (fieldHours) => {
      if (fieldHours.opening_time === fieldHours.closing_time) {
        dispatch(
          addNotification({
            message: 'The start time cannot be the end time',
            status: 'failed',
          }),
        );
        return false;
      }

      const existingFields = fields.filter(
        (field) => fieldHours.week_day === field.week_day && fieldHours?.id !== field.id,
      );

      for (const field of existingFields) {
        const newHoursOpen = fieldHours.opening_time;
        const newHoursClose = fieldHours.closing_time;
        const existHoursOpen = field.opening_time;
        const existHoursClose = field.closing_time;

        if (isTimeRangeOverlapping(newHoursOpen, newHoursClose, existHoursOpen, existHoursClose)) {
          dispatch(
            addNotification({
              message: 'Working time ranges overlap',
              status: 'failed',
            }),
          );
          return false;
        }

        if (isWrongRange(newHoursOpen, newHoursClose, existHoursOpen, existHoursClose)) {
          dispatch(
            addNotification({
              message: 'Start time cannot be after end time',
              status: 'failed',
            }),
          );
          return false;
        }
      }

      return true;
    },
    [dispatch, fields],
  );

  const handleFieldChange = (id, value, fieldName) => {
    setFields((prevFields) => {
      return prevFields.map((field) =>
        field.id === id
          ? { ...field, [fieldName]: fieldName === 'is_open' ? convertStringToBoolean(value) : value }
          : field,
      );
    });
  };

  const handleSave = () => {
    fields.forEach((field) => {
      const initialField = initialWorkingHours.find((initialField) => initialField.id === field.id);
      if (!isEqual(field, initialField)) {
        const { id, week_day, is_open, opening_time, closing_time } = field;
        const originalField = workingHours.find((day) => day.id === id);

        if (isHoursValidated(field)) {
          const updatedDetails = {
            week_day: week_day || originalField.week_day,
            is_open: is_open ?? originalField.is_open,
            opening_time: opening_time || originalField.opening_time,
            closing_time: closing_time || originalField.closing_time,
          };

          dispatch(updateExistingWorkingHours({ id, updatedDetails, token }));
        }
      }
    });
  };

  const handleCreate = useCallback(
    (newHours) => {
      if (isHoursValidated(newHours)) {
        dispatch(createWorkingHours({ newDayDetails: newHours, token }));
        setIsModalOpen(false);
      }
    },
    [dispatch, isHoursValidated, token],
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-hours',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this hours',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeWorkingHours({ id, token }));
                  dispatch(hideModal('modal-delete-hours'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'grey',
                onAction: () => dispatch(hideModal('modal-delete-hours')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={styles.main_container}>
      <PageTitle name="Business Hours" />
      <div className={styles.header}>
        <Button title="Add Day" onClick={handleAdd} style={{ margin: 0 }} />
      </div>
      <HoursList
        hours={fields}
        onStatusChange={handleFieldChange}
        onOpeningChange={handleFieldChange}
        onClosingChange={handleFieldChange}
        onDelete={handleDelete}
        isLoading={workingHoursStatus === 'loading'}
      />
      <Button title="Save" onClick={handleSave} disabled={hasUnsavedChanges} />
      <AddNewHours isOpen={isModalOpen} closeModal={handleModalClose} createNewHours={handleCreate} />
    </div>
  );
}

export default Hours;
