import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { useAppContext } from '../../../../context/context';

import { fetchOrdersReport } from '../../../../redux/reportsSetSlice';

import { HeaderReport, DateRangeFilter, ReportFilterPanel, DropdownTypes, PAGINATION } from '../components';

import OrdersSection from './orders-section';

import styles from './orders.module.scss';

function Orders() {
  const { token } = useAppContext();

  const dispatch = useDispatch();

  const paramsInit = {
    start_date: new Date(),
    end_date: new Date(),
    page: PAGINATION.page,
    per_page: PAGINATION.perPage,
  };

  const [filterParams, setFilterParams] = useState(paramsInit);

  useEffect(() => {
    dispatch(
      fetchOrdersReport({
        token,
        ...filterParams,
      }),
    );
  }, [dispatch, filterParams, token]);

  const downloadPdf = () => {};
  const downloadCsv = () => {};

  const handleSetFilterParams = (params) => {
    setFilterParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const handleClearFilters = () => {
    setFilterParams(paramsInit);
  };

  return (
    <div className={styles.contentWrapper}>
      <HeaderReport title={'Orders'} downloadPdf={downloadPdf} downloadCsv={downloadCsv} />
      <div className={clsx(styles.boxBody, styles.borderBox)}>
        <ReportFilterPanel
          filterParams={filterParams}
          searchShow={false}
          dropdowns={[DropdownTypes.employee]}
          onClearFilterParams={handleClearFilters}
          onUpdateParams={handleSetFilterParams}
        />
      </div>
      <div className={clsx(styles.boxBody, styles.borderBox)}>
        <DateRangeFilter
          startDate={filterParams.start_date}
          endDate={filterParams.end_date}
          showPreset={true}
          onUpdateParams={handleSetFilterParams}
        />
      </div>
      <OrdersSection filterParams={filterParams} onUpdateParams={handleSetFilterParams} />
    </div>
  );
}

export default Orders;
