import React, { useState, useEffect, useCallback } from 'react';
import { RxCross2 } from 'react-icons/rx';

import Modal from '../../../../components/utilities/modal/modal';
import Search from '../../../../components//utilities/search/search';
import Checkbox from '../../../../components/utilities/checkbox/checkbox';

import styles from './assign-items-modal.module.scss';

function AssignItemsCategoryModal({
  categories,
  isAssignItemsModal,
  setIsAssignItemsModal,
  handleCloseModal,
  handleUpdateCategory,
}) {
  const [searchTermAssignModal, setSearchTermAssignModal] = useState('');
  const [selectAll, setSelectAll] = useState({
    id: 0,
    name: 'Select All',
    status: false,
  });

  const [MockDataForMenuItems, setMockDataForMenuItems] = useState([
    { id: 1, name: 'Sell Card', status: true },
    { id: 2, name: 'Add Value', status: true },
    { id: 3, name: 'Balance Inquiry', status: false },
  ]);

  const [selectedModalItems, setSelectedModalItems] = useState([]);

  const [filteredAssgnModalItems, setFilteredAssgnModalItems] = useState(MockDataForMenuItems);

  const applyAssignModalFilters = useCallback(() => {
    let filtered = MockDataForMenuItems;

    if (searchTermAssignModal !== '') {
      filtered = filtered.filter((item) => item.name.toLowerCase().includes(searchTermAssignModal.toLowerCase()));
    }

    setFilteredAssgnModalItems(filtered);
  }, [MockDataForMenuItems, searchTermAssignModal]);

  useEffect(() => {
    setFilteredAssgnModalItems(MockDataForMenuItems);
  }, [MockDataForMenuItems]);

  useEffect(() => {
    applyAssignModalFilters();
  }, [MockDataForMenuItems, searchTermAssignModal, applyAssignModalFilters]);

  const handleSetAssignModalResult = (term) => {
    setSearchTermAssignModal(term);
  };

  const handleSearchAssignModalClean = () => {
    setSearchTermAssignModal('');
  };

  const handleAssignModalAll = (id, isChecked) => {
    setSelectAll((prev) => {
      return { ...prev, status: isChecked };
    });

    if (isChecked) {
      const allItemIds = MockDataForMenuItems.map((item) => item.id);
      setSelectedModalItems(allItemIds);
    } else {
      setSelectedModalItems([]);
    }
  };

  const handleAssignModalCheckboxChange = (id, isChecked) => {
    setSelectedModalItems((prev) => {
      let updatedItems;

      if (isChecked) {
        updatedItems = [...prev, id];
      } else {
        updatedItems = prev.filter((item) => item !== id);
      }

      setSelectAll({
        ...selectAll,
        status: updatedItems.length === MockDataForMenuItems.length,
      });

      return updatedItems;
    });
  };

  return (
    <Modal
      isOpen={isAssignItemsModal}
      onClose={() => handleCloseModal('assign_items')}
      className={styles.assign_items_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Apply Items to Category</h3>
          <div className={styles.cross_icon} onClick={() => handleCloseModal('assign_items')}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.search}>
            <Search
              placeholder="Search Items"
              onSearchResults={handleSetAssignModalResult}
              onClear={handleSearchAssignModalClean}
              autoFilter={false}
            />
          </div>
          <div className={styles.menu_items}>
            <div className={styles.menu_select_all}>
              <Checkbox item={selectAll} handleOnChange={handleAssignModalAll} isChecked={selectAll.status} />
              <span className={styles.menu_item_name}>Select All</span>
            </div>
            <div className={styles.menu_list_of_items}>
              {filteredAssgnModalItems.map((item) => (
                <div key={item.id} className={styles.menu_item}>
                  <Checkbox
                    item={item}
                    handleOnChange={handleAssignModalCheckboxChange}
                    isChecked={selectedModalItems.includes(item.id)}
                  />
                  <span className={styles.menu_item_name}>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <button type="button" className={styles.update_button} onClick={handleUpdateCategory}>
            Apply to Category
          </button>
          <button type="button" className={styles.cancel_button} onClick={() => setIsAssignItemsModal(false)}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AssignItemsCategoryModal;
