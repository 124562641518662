import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

import styles from './date-picker.module.scss';

const DatePicker = (props) => {
  const { errors = [], containerClassName, required, prefixIcon, ...rest } = props;

  return (
    <div className={clsx(styles.container, containerClassName)}>
      {props.label && (
        <label className={clsx(styles.label, required && styles.asterisk)} htmlFor={props.name}>
          {props.label}
        </label>
      )}
      <div className={styles.input_container}>
        {prefixIcon && <div className={styles.prefix_icon}>{prefixIcon}</div>}
        <div className={styles.date_picker}>
          <ReactDatePicker showMonthDropdown={true} showYearDropdown={true} dropdownMode="select" {...rest} />
        </div>
      </div>
      {!!errors.length && (
        <div className={styles.error_container}>
          {errors.map((error, index) => (
            <div key={index} className={styles.error}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
